<template>
    <div v-if="notes !== undefined">
        <h4 style="margin-bottom: 5px;"><strong>Notes </strong></h4>
        <div
            v-if="notes === null || verifyThereAreNoNotes(notes.filter(_ => !_.scope || _.scope === scope || _.scope === 'global'))"
            style="width: 100%; display: flex; align-items: center; padding-left: 10px">
            <b-card no-body style="margin-bottom: 5px; width: 100%; padding: 3px; background-color: #131313">
                <p style="text-align: center; width: 100%" class="noAdded"><i><strong>No Notes Added</strong></i></p>
            </b-card>
        </div>
        <div v-else>
            <div :key="i"
                 v-for="(note, i) in notes.filter(_ => !_.scope || _.scope === scope || _.scope === 'global')"
                 style="padding-left: 10px">
                <b-card no-body style="margin: 0 0 5px 0; width: 100%; padding: 3px; background-color: #131313"
                        class="d-flex flex-row justify-content-between flex-wrap">
                    <div v-if="note.text !== ''" style="width: fit-content; word-break: break-word">
                        <b>{{ (i + 1) + ") " }}</b>{{ note.text }}
                    </div>
                    <div class="text-muted text-uppercase font-weight-bold font-xs flex-grow-1 text-right"
                         style="width: fit-content">Created By: {{ note.createdBy }}
                    </div>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotesCardComponent",
    props: {
        notes: { type: Array },
        scope: { type: String }
    },
    methods: {
        verifyThereAreNoNotes(notes) {
            try {
                const notesLengthEqualToZero = notes.every(note => {
                    return note.text.length === 0;
                })
                return !!notesLengthEqualToZero;
            } catch (err) {
                return true
            }
        },
    }
}
</script>

<style scoped>

.noAdded {
    text-align: center;
    margin-bottom: 0;
    color: grey
}

</style>